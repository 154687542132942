import { ResellerAdvantagesComponent } from './../reseller-advantages/reseller-advantages.component';
import { Component } from '@angular/core';
import { fadeInLeftOnEnterAnimation } from 'angular-animations';
import { WhyPartnerWithUsComponent } from '../why-partner-with-us/why-partner-with-us.component';
import { BecomeAResllerComponent } from '../become-a-resller/become-a-resller.component';

@Component({
  selector: 'app-reseller',
  standalone: true,
  imports: [WhyPartnerWithUsComponent,ResellerAdvantagesComponent,BecomeAResllerComponent],
  templateUrl: './reseller.component.html',
  styleUrl: './reseller.component.scss',
  animations:[fadeInLeftOnEnterAnimation(),]
})
export class ResellerComponent {

}
