<section id="our_vision_container" class="our_vision_container">
  <div [@slideInOut]
    class=" flex flex-col items-start justify-start gap-6 py-32 md:py-24 lg:py-48 px-4 md:pl-14 lg:pl-[108px]">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100" id="our-vision-head"> Our Vision</p>
    <p class="text-hurrey-16 text-black break-words md:w-[70%]" id="atomo_s_vision"> Our vision propels us as a leader in
      AI-powered education software in India towards limitless
      understanding, where knowledge is accessible, deep, and evolves alongside individuals.
      Grounded in transparency, we empower informed decisions, nurturing curiosity and innovation
      for a future where understanding shapes destinies. Together, we create endless possibilities, one
      insight at a time.</p>

  </div>
</section>
