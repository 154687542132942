import { Component } from '@angular/core';

@Component({
  selector: 'become-a-reseller',
  standalone: true,
  imports: [],
  templateUrl: './become-a-resller.component.html',
  styleUrl: './become-a-resller.component.scss'
})
export class BecomeAResllerComponent {

}
