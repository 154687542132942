<section id="atomo-super-app"
  class="flex flex-col items-center justify-center px-4 md:px-0 lg:px-0 py-10 md:pt-6 lg:pt-10 md:pb-0 lg:pb-0 ">
  <p class="text-purple_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 "> Atomo's Super App Ecosystem</p>

  <div class="grid grid-cols-2 gap-8 ">
    <div class="col-span-2 md:col-span-1">
      <img src="../../../../assets/images/device_with_screen.webp" alt="hurrey_device" class="hidden md:block">
      <img src="../../../../assets/images/device_with_screen_mobile.webp" alt="hurrey_device_for mobile"
        class="block md:hidden">
    </div>
    <div class="col-span-2 md:col-span-1 flex flex-col gap-2 items-start justify-center md:pr-14 lg:pr-[108px]">

      <p class="text-purple_100 text-hurrey-28" id="atomo_hardware_as_a_service"> Hardware + Software</p>
      <p class=" text-hurrey-12 lg:text-hurrey-16 mb-3" id="atomo_hardware_as_a_service_description"><span
          class="text-black">Konnect Device </span> <br>
        <span class="text-grey"> Introducing Atomo, a leading AI-powered learning solutions provider in India, with a
          revolutionary pocket-sized device designed to transform classrooms in no or low-internet zones
          into smart classrooms. This innovative solution enables teachers to effortlessly cast course
          content from their smartphones to TVs without needing an internet connection, effectively
          turning their phones into secure whiteboards. Backed by a reliable warranty, it surpasses
          traditional projectors and built-in smart TV features, offering unmatched versatility without
          relying on high-speed internet. Additionally, our comprehensive app suite seamlessly powers all
          operational aspects, making Atomo a game-changer in the field of educational technology.</span>
      </p>



    </div>
  </div>


  <div class="grid grid-cols-2 gap-2">
    <div
      class="col-span-2 md:col-span-1 flex flex-col order-last md:order-first gap-2 md:gap-4 items-start justify-center md:pl-14 lg:pl-[108px]">

      <p class="text-purple_100 text-hurrey-28" id="atomo_software_as_a_software"> Software</p>
      <p class=" text-hurrey-12 mb-3 lg:text-hurrey-16" id="atomo_software_as_a_software_description"><span
          class="text-black">Atomo Education OS </span> <br>
        <span class="text-grey"> For both non-smart and smart classrooms with reliable internet, our EdTech solutions in
          India
          significantly enhance educational experiences through Atomo&#39;s suite of apps. This empowers school
          administrations with efficient operations, including class and teacher performance metrics. Teachers can
          manage their workload and personalize course materials, facilitating offline assessments. Meanwhile,
          students benefit from advanced productivity features such as AI personalization, solvers, language
          learning tools, and enablers.</span>
      </p>



    </div>

    <div class="col-span-2 md:col-span-1">
      <img src="../../../../assets/images/device_screen.webp" alt="hurrey_device" class="hidden md:block ">
      <img src="../../../../assets/images/device_screen_mobile.webp" alt="hurrey_device_for_mobile"
        class="block md:hidden">
    </div>
  </div>

</section>
