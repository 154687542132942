<div class="black_div"></div>


<div class="content_wrapper py-4">
  <div class="content">
    <div class="flex flex-col gap-2 mb-5">
      <h1 class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 bold_font">Privacy Policy</h1>
      <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">Welcome to The Atomo . Your privacy is important to
        us, and we are committed to protecting
        the personal information you share with us. This Privacy Policy explains how we collect, use,
        and disclose your information when you visit our website, &quot;https://www.theatomo.com/&quot;</p>
    </div>


    <strong class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">By accessing or using our Site, you
      agree to this
      Privacy Policy. If you do not agree with our
      policies and practices, please do not use our Site.</strong>

    <h2 class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font mt-5">Information We Collect</h2>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">We may collect several
      types of information from
      and about users of our Site, including:</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 mb-2"><strong>Personal Information: </strong>This
      includes
      information that can be used to identify you, such as your
      name, email address, phone number, and any other details you voluntarily provide when you
      contact us, subscribe to our newsletter, or make a purchase. </p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 mb-2"><strong>Non-Personal Information: </strong>This
      includes information that does not directly identify you, such
      as your browser type, IP address, device information, and usage data related to your
      interactions with our Site. </p>

    <h3 class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-3 md:my-4 lg:my-6 bold_font">How We Use Your
      Information</h3>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">We use the information we collect to:</p>

    <ul class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 flex flex-col gap-2">
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Provide, operate, and maintain our Site
        and services.</li>
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Process and manage your orders and
        inquiries.</li>
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Communicate with you, including sending
        you updates, promotional materials, and
        newsletters.</li>
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Improve our Site and services by
        analyzing usage trends and feedback.</li>
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Protect against fraudulent or illegal
        activities.</li>
    </ul>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Sharing Your
      Information</p>

    <ul class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- We do not sell or rent your personal
        information to third parties. We may share your
        information with:</li>
    </ul>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Service Providers</p>

    <ul class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">
      <li class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Third-party vendors who assist us in
        operating our Site, conducting our business, or
        serving our users, as long as those parties agree to keep this information confidential.</li>
    </ul>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-2 md:my-4 lg:my-6"><strong class="bold_font">Legal
        Compliance**:
      </strong>If required by law or in response to legal processes, we may disclose your
      information to comply with legal obligations.</p>
    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-2 md:my-4 lg:my-6"><strong
        class="bold_font">Business Transfers:
      </strong>In the event of a merger, acquisition, or sale of all or a portion of our assets,
      your personal information may be transferred as part of that transaction.</p>


    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Cookies and Tracking
      Technologies</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">- We use cookies and similar tracking technologies to
      enhance your experience on our
      Site. Cookies are small data files that are placed on your device when you visit a
      website. You can set your browser to refuse all or some cookies or to alert you when
      cookies are being sent.</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4">Your Choices</p>
    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4">You have the right to:</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-2 md:my-4 lg:my-6"><strong class="bold_font">Access
        and Update:
      </strong>You can access and update your personal information by contacting us
      directly.</p>
    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-2 md:my-4 lg:my-6"><strong class="bold_font">-
        **Opt-Out**:
      </strong>You can opt out of receiving promotional emails by following the unsubscribe
      instructions included in those emails.</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 my-2 md:my-4 lg:my-6"><strong
        class="bold_font">-Request Deletion:
      </strong>You may request the deletion of your personal information by contacting
      us, subject to certain legal obligations.</p>


    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Data Security</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">- We take reasonable measures to protect your personal
      information from unauthorized
      access, alteration, or disclosure. However, no method of transmission over the internet
      or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Children’s Privacy</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24">- Our Site is not intended for children under 13 years
      of age. We do not knowingly collect
      personal information from children under 13. If you are under 13, please do not provide
      any information on our Site.</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Changes to This Privacy
      Policy</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font">- Our Site is not intended for children
      under 13 years of age. We do not knowingly collect
      personal information from children under 13. If you are under 13, please do not provide
      any information on our Site.</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">Contact Us</p>

    <p class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24 bold_font my-2 md:my-4 lg:my-6">If you have any
      questions or concerns about this Privacy Policy or our privacy practices,
      please contact us at:</p>

    <div class="flex flex-col gap-2">
      <a class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24" href="mailto:info@theatomo.com">
        <strong class="bold_font">Email:</strong> info&#64;theatomo.com</a>

      <a class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24" href="tel:+91 82104 03625">
        <strong class="bold_font">Phone:</strong> +91 82104 03625
      </a>

      <address class="text-hurrey-12 md:text-hurrey-20 lg:text-hurrey-24"><strong class="bold_font">Address:</strong>
        503, Brigade IRV Center, Whitefield, Bengaluru, Karnataka, 560066, INDIA</address>

    </div>


  </div>
</div>
