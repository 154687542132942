import { Component } from '@angular/core';

@Component({
  selector: 'why-partner-with-us',
  standalone: true,
  imports: [],
  templateUrl: './why-partner-with-us.component.html',
  styleUrl: './why-partner-with-us.component.scss'
})
export class WhyPartnerWithUsComponent {

}
