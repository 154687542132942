<div class="flex flex-col gap-4">
  <h3 class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 ">Why Partner with Us?</h3>

  <div class="grid grid-cols-12 gap-3">
    <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2 flex flex-col gap-3 rounded-md medium_box_shadow">
      <div class="h-[300px] flex justify-center items-center bg-sky_lightest">
        <img src="../../../../assets/images/hurrey_konnect_device.webp" alt="konnect-device"
          class="object-contain h-full w-full">
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-semibold text-center">Innovative Technology</p>
      <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 text-ink_light">At Atomo, we harness AI to deliver personalized
        learning experiences tailored to each student’s unique needs.
        Our LMS integrates seamlessly with IoT devices and works offline, ensuring learning anytime, anywhere.</p>
    </div>
    <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2 flex flex-col gap-3 rounded-md medium_box_shadow">
      <div class="h-[300px] flex justify-center items-center">
        <img src="../../../../assets/images/support_and_training.webp" alt="konnect-device"
          class="object-cover h-full w-full">
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-semibold text-center">Support and
        Training</p>
      <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 text-ink_light">We empower our resellers with expert training,
        tools, and unwavering support to ensure their success. Partner with us and confidently transform education with
        Atomo.</p>
    </div>


    <div class="col-span-12 md:col-span-6 lg:col-span-4 p-2 flex flex-col gap-3 rounded-md medium_box_shadow">
      <div class="h-[300px] flex justify-center items-center">
        <img src="../../../../assets/images/profit_opportunity.webp" alt="konnect-device"
          class="object-cover h-full w-full">
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-semibold text-center">Profitable Opportunity</p>
      <p class="text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20 text-ink_light">Unlock a lucrative opportunity with Atomo's
        reseller program. Capitalize on the growing demand for advanced educational solutions and enjoy competitive
        pricing with healthy margins.</p>
    </div>

  </div>


</div>
