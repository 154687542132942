<div class="flex flex-col gap-4 md:gap-8">
  <h3 class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 ">Become a Reseller in 3 Easy Steps</h3>
  <div class="flex flex-col lg:flex-row items-center justify-between gap-8">
    <!-- Step 1 -->
    <div class="text-center flex flex-col items-center gap-3">
      <div class="h-12 w-12 md:h-16 md:w-16 flex items-center justify-center blink_div text-hurrey-20 rounded-half">
        1
      </div>
      <a href="https://forms.gle/Qxo6QUKRNsZMA4Tm8" target="_blank" class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24  hover:text-purple_100 cursor-pointer blinking-text font-extrabold">Apply Online</a>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Fill out our quick application form to get started.</p>
    </div>

    <!-- Connector -->
    <div class="hidden lg:block h-1 w-16 bg-sky_dark"></div>

    <!-- Step 2 -->
    <div class="text-center flex flex-col items-center gap-3">
      <div class="h-12 w-12 md:h-16 md:w-16 flex items-center justify-center bg-purple_100 text-white text-hurrey-20 rounded-half">2
      </div>
      <h3 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 font-semibold">Training & Onboarding</h3>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Receive comprehensive training and support.</p>
    </div>

    <!-- Connector -->
    <div class="hidden lg:block h-1 w-16 bg-sky_dark"></div>

    <!-- Step 3 -->
    <div class="text-center flex flex-col items-center gap-3">
      <div class="h-12 w-12 md:h-16 md:w-16 flex items-center justify-center bg-purple_100 text-white text-hurrey-20 rounded-half">3
      </div>
      <h3 class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 font-semibold">Start Selling & Earning</h3>
      <p class="text-hurrey-12 md:text-hurrey-[14px] lg:text-hurrey-16 text-sky_dark">Begin offering Atomo solutions and grow your business.</p>
    </div>
  </div>
</div>
