import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { slideInDownOnEnterAnimation } from 'angular-animations';
import { ourMission } from '../../../shared/models/our-mission.model';

@Component({
  selector: 'our-mission',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './our-mission.component.html',
  styleUrl: './our-mission.component.scss',
  animations: [slideInDownOnEnterAnimation({ anchor: 'slideInDown' })],
})
export class OurMissionComponent {
  selectedTab: string = 'tab1';

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  details: ourMission[] = [
    {
      title: 'Atomo for Startups',
      mission: 'Atomo for startup Mission',
      about:
        'Embark on a transformative journey with Schools as we offer an exclusive opportunity for growth and innovation.',
      description:
        'As your school integrates the best LMS in India for an AI-powered upgrade, a neighboring school will also benefit from a complimentary upgrade. This dual transformation not only enhances your institution but also elevates the local educational landscape, fostering a technologically empowered and future- ready learning environment for all.',
      number: '1',
    },

    {
      title: 'Nation Building',
      mission: 'Atomo’s Nation Building Mission',
      about:
        "For nearly 50 years, the foundation of technological nation-building has been the effective harnessing of artificial intelligence's transformative power.",
      description:
        'At Atomo, the leading AI-powered education platform in India, we believe that education is the cornerstone of national development. By empowering individuals with essential skills, we’re building a highly skilled workforce, driving innovation, and actively contributing to India’s progress and growth.',
      number: '2',
    },

    {
      title: 'Green School',
      mission: 'Atomo’s Green School Mission',
      about:
        'Atomo spearheads an educational revolution by seamlessly integrating sustainability and reshaping the learning landscape with innovative AI.',
      description:
        "Atomo seamlessly integrates into various setups, providing compact and energy-efficient hardware for sustainable education. By eliminating complex infrastructures, Atomo's commitment to sustainability aligns with eco-friendly standards, reshaping the learning landscape for a brighter future.",
      number: '3',
    },
  ];
}
