import { Component } from '@angular/core';

@Component({
  selector: 'reseller-advantages',
  standalone: true,
  imports: [],
  templateUrl: './reseller-advantages.component.html',
  styleUrl: './reseller-advantages.component.scss'
})
export class ResellerAdvantagesComponent {

}
