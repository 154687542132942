import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  slideInDownOnEnterAnimation,
  slideInUpOnEnterAnimation,
} from 'angular-animations';
import { trustedBycustomers } from '../../../shared/models/trusted-by-customers.model';

@Component({
  selector: 'trusted-by-customers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './trusted-by-customers.component.html',
  styleUrl: './trusted-by-customers.component.scss',
  animations: [
    slideInUpOnEnterAnimation({ anchor: 'slideInOut' }),
    slideInDownOnEnterAnimation({ anchor: 'slideInDown' }),
  ],
})
export class TrustedByCustomersComponent {
  customers: trustedBycustomers[] = [
    {
      title: 'Revolutionizing ',
      description:
        'Atomo transforms education by analyzing individual knowledge levels and delivering tailored content to accelerate learning. Its applications span Education, Training, and HR, enabling a personalized, engaging learning experience. Looking forward to using Atomo in my learning journey!',
      imgUrl: '../../../../assets/images/amol_maheshwari.webp',
      name: 'Amol Maheshwari',
      designation: 'Founder, Happiness Gurukul',
    },
    {
      title: 'Impressive ',
      description:
        'I’m grateful for the opportunity to be introduced to this program and would definitely recommend it—it’s truly impressive! While it does require some training to get the best out of it, I’m confident it’s worth it.',
      imgUrl: '../../../../assets/images/Dana.webp',
      name: 'Dana',
      designation: 'Educational Enthusiast',
      link:'https://youtu.be/9vprVxZsRmY'
    },
    {
      title: 'Exciting and Innovative',
      description:
        'This program showcases the beauty of smart technology and how rapidly the world is advancing. It’s exactly what we need to bring into our learning environments to keep up with these exciting changes.',
      imgUrl: '../../../../assets/images/Makena.webp',
      name: 'Makena',
      designation: 'Program Advocate',
      link:'https://youtu.be/JRAr9yCtKyw'
    },
    {
      title: 'A Game-Changer in Education',
      description:
        'With over 30 years in education, I’ve seen how progress has transformed learning, assessment, and access for students. This program is a powerful way to leverage AI for even better outcomes, making it a valuable tool in modern education.',
      imgUrl: '../../../../assets/images/yasmin.webp',
      name: 'Yasmin',
      designation: 'Educational Expert',
      link:'https://youtu.be/GEoUROeMPKM'
    },
  ];

  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: true,
  //   infinite: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 300, // Animation speed
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1
  //       }
  //     }
  //   ]
  // };
}
