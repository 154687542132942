<section class="konnect-device_container" id="konnect-device-container">
  <div
    class="grid grid-cols-2 md:gap-[74px] lg:gap-[108px] gap-4 pt-10 md:pt-24 lg:pt-[185px] pl-4 lg:pl-[108px] md:pl-14 ">
    <div class="col-span-2 md:col-span-1 ">
      <div class="flex flex-col gap-6 md:gap-4  ">
        <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="atomo_konnect_hardware"> The
          Konnect Device- <br> Hardware</p>
        <p class="text-white text-hurrey-16 lg:text-hurrey-16 md:text-hurrey-12"
          id="atomo_konnect_hardware_description"> As an AI-powered learning solutions provider in India, Atomo is
          creating a unified educational ecosystem
          designed to break down data silos, transcend departmental boundaries, and foster collaboration. Our
          aim is to connect everyone within the educational landscape, promoting seamless information sharing,
          holistic learning, and innovation for a more integrated and enriched educational experience.</p>
        <!-- <button class="buy_now_button hidden md:block" routerLink="/pricing" id="buy_now"> Buy now</button> -->
      </div>
    </div>
    <div class="col-span-2 md:col-span-1 flex items-start justify-between md:items-center md:justify-center  ">
      <!-- <button class="buy_now_button block md:hidden" routerLink="/pricing" id="buy_now"> Buy now</button> -->
      <img src="../../../../assets/images/hurrey_konnect_device.webp" alt="konnect_hardware_device" class="image">
    </div>

  </div>


</section>
