<section class="bg-rose_100" id="atomo-tech-stack-container">
  <div class="grid grid-cols-2 gap-6 md:gap-9 py-14 md:py-8 lg:py-16">
    <div class="col-span-2 md:col-span-1 flex flex-col items-center justify-center px-4 md:pl-14  lg:pl-[108px]">
      <div class="flex flex-col gap-2 md:gap-6 ">
        <p class="text-white text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36" id="atomo_tech_stack"> Atomo's Tech
          Stack</p>
        <p class="text-white text-hurrey-16 md:text-hurrey-12 lg:text-hurrey-16 " id="atomo-tech_stack_description">
          Atomo, a premier provider of EdTech solutions in India, seamlessly integrates hardware and software to
          deliver a robust and comprehensive educational platform. Built on cutting-edge, resilient technology,
          Atomo embodies precision and stability, establishing itself as a leader in educational innovation. By
          harnessing advanced technologies like IoT (Internet of Things), Cloud Computing, Machine Learning, and
          AI, Atomo strengthens its platform to meet modern educational needs. Additionally, Atomo partners
          with top tech providers to license industry-leading components, further enriching our services with best-
          in-class innovations.</p>

      </div>

    </div>
    <div class="col-span-2 md:col-span-1 flex items-center justify-center">
      <img src="../../../../assets/images/tech_stack.webp" alt="tech_stack_pattern">

    </div>

  </div>

</section>
